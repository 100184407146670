import clsx from 'clsx';
import Link from 'next/link';
import { ContentGroupItemProps } from '../types';

const Content = (props: ContentGroupItemProps) => {
  const { title, url, description, isHighlighted } = props;
  const additional = title.additional;
  return (
    <Link href={url} passHref>
      <a>
        <div
          className={clsx(
            'md:text-sm lg:px-12 lg:hover:bg-neutral-1 py-8 transition-all duration-150 rounded-2',
            {
              'my-8 lg:py-20 lg:bg-neutral-1 lg:my-18 border border-transparent lg:hover:border-neutral-3':
                isHighlighted,
              'my-8 lg:my-6': !isHighlighted,
            },
          )}
        >
          <div>
            <div className="font-bold text-neutral-9">
              <span
                className="mr-6"
                dangerouslySetInnerHTML={{ __html: title.label }}
              />
              {additional && (
                <span
                  className="font-normal"
                  dangerouslySetInnerHTML={{ __html: additional }}
                />
              )}
            </div>
          </div>
          {description && (
            <div
              className="text-neutral-5 text-sm hidden lg:block"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}
        </div>
      </a>
    </Link>
  );
};

export default Content;
