import Image from 'next/image';
import { ContentGroupHeaderProps } from '../types';

const Header = (props: ContentGroupHeaderProps) => {
  const { title, icon } = props;

  return (
    <div className="flex items-center justify-between lg:px-12 ">
      <span
        className="text-neutral-5 text-xs tracking-[0.1rem] xl:text-xxs uppercase font-extrabold"
        dangerouslySetInnerHTML={{ __html: title }}
      />
      {icon && (
        <div className="relative w-18 h-18">
          <Image src={icon} layout="fill" objectFit="contain" alt="ikona" />
        </div>
      )}
    </div>
  );
};

export default Header;
