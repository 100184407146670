import Image from 'next/image';
import MenuButton from '../../atoms/MenuButton';
import MenuHeading from '../../atoms/MenuHeading';
import { IButtonACF } from 'src/types/IButtonACF';
import clsx from 'clsx';

export interface TileGroupProps {
  title?: string;
  content?: string;
  image?: string;
  button?: IButtonACF;
  is_image_background?: boolean;
}

const TileGroup = (props: TileGroupProps) => {
  const { title, content, button, image, is_image_background } = props;
  const hasButton = button?.url && button?.title;

  return (
    <div
      className={clsx('p-32 bg-neutral-1 relative', {
        'flex flex-col justify-between w-full': !is_image_background,
      })}
    >
      {image && is_image_background && (
        <div className="mb-16">
          <Image src={image} layout="fill" objectFit="cover" alt="" />
        </div>
      )}
      {image && !is_image_background && (
        <div className="mb-32 lg:mb-45 lg:mt-24 flex justify-center">
          <Image src={image} width={100} height={100} alt="" />
        </div>
      )}
      <div
        className={clsx('relative z-10', {
          'pt-112': is_image_background,
        })}
      >
        {title && (
          <div
            className={clsx('mb-4', {
              'text-neutral-0': is_image_background,
              'text-neutral-9': !is_image_background,
            })}
          >
            <MenuHeading title={title} classNames="text-lg xl:text-base" />
          </div>
        )}

        {content && (
          <p
            className={clsx('text-sm [&_a]:font-bold [&_a]:hover:underline', {
              'text-neutral-0': is_image_background,
              'text-neutral-5': !is_image_background,
            })}
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          />
        )}
      </div>
      {hasButton && (
        <div
          className={clsx({
            'text-neutral-0 relative z-10': is_image_background,
            'text-neutral-9 mt-16': !is_image_background,
          })}
        >
          <MenuButton {...button} />
        </div>
      )}
    </div>
  );
};

export default TileGroup;
