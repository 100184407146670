import Button from '@components/views/Button';
import clsx from 'clsx';
import React from 'react';
import { IButtonACF } from 'src/types/IButtonACF';

const MenuButton = (
  props: IButtonACF & {
    hasDefaultMarginTop?: boolean;
    prefix?: string;
  },
) => {
  const { url, title, hasDefaultMarginTop, variant, showOnDevice, prefix } =
    props;

  return (
    <div
      className={clsx('w-full text-left flex items-center gap-16 flex-wrap', {
        'mt-16': hasDefaultMarginTop,
      })}
    >
      {prefix && (
        <p
          dangerouslySetInnerHTML={{ __html: prefix }}
          className="text-sm text-neutral-7"
        />
      )}
      <Button
        href={url}
        variant={variant}
        showOnDevice={showOnDevice}
        noPadding
      >
        {title}
      </Button>
    </div>
  );
};

MenuButton.defaultProps = {
  hasDefaultMarginTop: true,
};

export default MenuButton;
