import clsx from 'clsx';
import Image from 'next/image';
import ChevronIcon from '../../../assets/images/icons/chevron-right.svg';

interface SlideIcon {
  iconUrl: string;
  additionalClasses?: {
    [key: string]: boolean;
  };
}

const SlideAnimatedIcon = ({ iconUrl, additionalClasses }: SlideIcon) => {
  return (
    <div
      className={clsx(
        'flex items-start justify-start flex-shrink-0 flex-col h-24 overflow-hidden ',
        { ...additionalClasses },
      )}
    >
      <div
        className={clsx({
          'transition-all duration-150 ease-linear group-hover:-translate-y-1/2':
            !!iconUrl,
        })}
      >
        <div className="w-24 h-24 flex items-center flex-shrink-0 justify-center">
          <div className="relative w-18 h-18">
            <Image
              src={iconUrl}
              layout="fill"
              objectFit="contain"
              alt="ikona"
            />
          </div>
        </div>

        <div className="w-24 h-24 flex items-center flex-shrink-0 justify-center">
          <ChevronIcon fill="currentColor" />
        </div>
      </div>
    </div>
  );
};

export default SlideAnimatedIcon;
