import Button from '@components/views/Button';
import clsx from 'clsx';
import { IButtonACF } from 'src/types/IButtonACF';

export interface CTAGroupData {
  content?: string;
  title?: string;
  buttons: Array<IButtonACF>;
}

const CTAGroup = (props: CTAGroupData) => {
  const { content, buttons, title } = props;

  return (
    <div className="p-24 border border-neutral-2 rounded-2">
      {title && (
        <p
          className="text-xl font-extrabold text-neutral-9 mb-8"
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
      )}

      {content && (
        <p
          className="text-sm text-neutral-5"
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
      )}
      {buttons?.length > 0 && (
        <div className="flex flex-wrap space-between items-center">
          {buttons.map(({ title, url }, index) => (
            <div
              key={index}
              className={clsx('mt-12', {
                'mr-16': index === 0,
              })}
            >
              <Button href={url} variant={'LINK'} noPadding>
                {title}
              </Button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CTAGroup;
