import clsx from 'clsx';
import ContentGroup from '../molecules/ContentGroup';
import CTAGroup from '../molecules/CTAGroup';
import FeaturedContentGroup, {
  FeaturedContentGroupProps,
} from '../molecules/FeaturedContentGroup';
import ListGroup from '../molecules/ListGroup';
import TileGroup from '../molecules/TileGroup';

const renderColumns = (
  columns: any,
  hasFeaturedContent?: boolean,
  hasTileGroups?: boolean,
) => {
  return (
    <div
      className={clsx('grid gap-16 lg:gap-32', {
        'lg:col-span-1 xl:col-span-3 lg:grid-cols-3': hasFeaturedContent,
        'lg:col-span-1 xl:col-span-3 lg:grid-cols-[1fr_1fr_27rem]':
          hasTileGroups && hasFeaturedContent,
        'xl:grid-cols-4': !hasFeaturedContent,
        'xl:grid-cols-[1fr_1fr_1fr_27rem]':
          hasTileGroups && !hasFeaturedContent,
      })}
    >
      {columns.map((column: any, key: number) => {
        const { layout } = column;
        return (
          <div key={`column-${key}`} className="space-y-8">
            {layout.map((_layout: any, index: any) => {
              if (_layout.acf_fc_layout === 'content_groups') {
                const {
                  content_group: { heading, items, button },
                } = _layout;
                return (
                  <div key={index}>
                    <ContentGroup {...{ heading, items, button }} />
                  </div>
                );
              }

              if (_layout.acf_fc_layout === 'list_groups') {
                const { button, heading, isHighlighted, items } = _layout;

                return (
                  <div key={index}>
                    <ListGroup {...{ button, heading, isHighlighted, items }} />
                  </div>
                );
              }

              if (_layout.acf_fc_layout === 'cta_groups') {
                const { buttons, content, title } = _layout;

                return (
                  <div key={index}>
                    <CTAGroup {...{ buttons, content, title }} />
                  </div>
                );
              }

              if (_layout.acf_fc_layout === 'tile_groups') {
                const { title, content, image, button, is_image_background } =
                  _layout;

                return (
                  <div
                    key={index}
                    className={clsx({ 'flex h-full': !is_image_background })}
                  >
                    <TileGroup
                      {...{
                        title,
                        content,
                        image,
                        button,
                        is_image_background,
                      }}
                    />
                  </div>
                );
              }

              return <div key={index}></div>;
            })}
          </div>
        );
      })}
    </div>
  );
};

interface MenuGridProps {
  featured_content: FeaturedContentGroupProps;
  columns: any;
}

const MenuGrid = (props: MenuGridProps) => {
  const { featured_content, columns } = props;
  const hasFeaturedContent =
    featured_content.content || featured_content.content;
  const hasColumns = columns && columns.length > 0;
  const hasTileGroups =
    hasColumns &&
    columns.some(({ layout }: { layout: any }) =>
      layout.some((item: any) => item.acf_fc_layout === 'tile_groups'),
    );

  return (
    <div className={clsx('flex flex-col xl:flex-row xl:items-start')}>
      {hasFeaturedContent && (
        <div className="xl:max-w-[27rem] w-full xl:mr-32 mb-32 xl:mb-0">
          <FeaturedContentGroup {...featured_content} />
        </div>
      )}
      {hasColumns && (
        <div
          className={clsx('grid', {
            'xl:grid-cols-[1fr_1fr_1fr] gap-32 xl:gap-100':
              !!hasFeaturedContent,
          })}
        >
          {renderColumns(columns, !!hasFeaturedContent, hasTileGroups)}
        </div>
      )}
    </div>
  );
};

export default MenuGrid;
