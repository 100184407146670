import { ListGroupHeader } from '../types';

const Header = (props: ListGroupHeader) => {
  const { title } = props;

  return (
    <div className="flex items-center justify-between lg:px-8 xl:px-32">
      <span
        className="text-neutral-5 text-xs tracking-[0.1rem] xl:text-xxs uppercase font-extrabold"
        dangerouslySetInnerHTML={{ __html: title }}
      />
    </div>
  );
};

export default Header;
