import Link from 'next/link';
import clsx from 'clsx';
import { ListGroupItem } from '../types';
import SlideAnimatedIcon from '@components/views/SlideAnimatedIcon';

const ListItem = (props: ListGroupItem & { isHighlighted?: boolean }) => {
  const { label, icon, url, description, isHighlighted } = props;

  if (!label) return null;

  const ComponentBody = (
    <div className={clsx('flex items-center group w-full')}>
      {icon && (
        <SlideAnimatedIcon
          iconUrl={icon}
          additionalClasses={{
            'bg-neutral-0 rounded-12': description && label,
          }}
        />
      )}
      <div
        className={clsx('flex flex-col text-left w-full', {
          'ml-8': !!icon,
        })}
      >
        <span
          className={clsx('xl:text-sm font-medium text-left', {
            'transiton-transform duration-150 group-hover:translate-x-8':
              !!url && !isHighlighted,
            'flex items-center justify-between transiton duration-150 hover:text-neutral-8':
              !!url && isHighlighted,
          })}
        >
          {typeof label === 'string' ? (
            <span dangerouslySetInnerHTML={{ __html: label }} />
          ) : (
            label
          )}
          {!!url &&
            isHighlighted &&
            (url.startsWith('https://') || url.startsWith('http://')) && (
              <SlideAnimatedIcon
                iconUrl={'/images/url.svg'}
                additionalClasses={{
                  'mx-8': true,
                }}
              />
            )}
        </span>
        {description && (
          <p
            className="text-neutral-5 text-xs"
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        )}
      </div>
    </div>
  );

  if (url) {
    return (
      <Link href={url} passHref>
        <a>{ComponentBody}</a>
      </Link>
    );
  }

  return ComponentBody;
};

export default ListItem;
