import clsx from 'clsx';

export interface MenuHeadingProps {
  title: string;
  hasSmallerFont?: boolean;
  classNames?: string;
}

const MenuHeading = (props: MenuHeadingProps) => {
  const { title, hasSmallerFont, classNames } = props;
  return (
    <h5
      className={clsx(`font-bold ${classNames || ''}`, {
        'text-lg lg:text-2xl': !hasSmallerFont,
        'text-sm lg:text-lg': hasSmallerFont,
      })}
    >
      {title}
    </h5>
  );
};

export default MenuHeading;
