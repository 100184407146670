import clsx from 'clsx';
import MenuButton from '../../atoms/MenuButton';
import Header from './atoms/Header';
import ListItem from './atoms/ListItem';
import { ListGroupProps } from './types';
import Container from '@components/containers/Container';

const ListGroup = (props: ListGroupProps) => {
  const { heading, items, button, isHighlighted } = props;
  const hasButton = button?.url && button?.title;
  const hasItems = items && items.length > 0;
  const hasAnyContent = hasItems || hasButton;

  if (!hasAnyContent) return null;

  return (
    <Container
      className={clsx('mb-32', {
        'bg-neutral-1 xl:-mt-24 rounded-2 p-32 -mx-16 xl:px-0 xl:py-24 xl:mx-0':
          isHighlighted,
      })}
    >
      <div className="mb-34 xl:mb-21">
        <Header {...heading} />
      </div>

      {hasItems &&
        items.map((item, key) => (
          <div className="mb-20 last:mb-0 xl:px-32" key={item.label + key}>
            <ListItem {...item} isHighlighted={isHighlighted} />
          </div>
        ))}

      {hasButton && (
        <div className="lg:mx-32">
          <MenuButton {...button} />
        </div>
      )}
    </Container>
  );
};

export default ListGroup;
