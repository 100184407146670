import MenuButton from '../../atoms/MenuButton';
import MenuHeading from '../../atoms/MenuHeading';
import clsx from 'clsx';
import { IButtonACF } from 'src/types/IButtonACF';

export interface FeaturedContentGroupProps {
  heading: string;
  content?: string;
  button?: IButtonACF;
  button_prefix?: string;
}

const FeaturedContentGroup = (props: FeaturedContentGroupProps) => {
  const { heading, content, button, button_prefix: buttonPrefix } = props;
  const hasButton = button?.url && button?.title;

  return (
    <div className="w-full">
      <div className="mb-16">
        <MenuHeading title={heading} />
      </div>

      <p
        className={clsx('text-sm lg:text-lgHigh text-neutral-7', {
          'mb-8 lg:mb-16': !hasButton,
          'mb-16 lg:mb-36': hasButton,
        })}
        dangerouslySetInnerHTML={{
          __html: content || '',
        }}
      />

      {hasButton && <MenuButton {...button} prefix={buttonPrefix} />}
    </div>
  );
};

export default FeaturedContentGroup;
