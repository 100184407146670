import Header from './atoms/Header';
import Item from './atoms/Item';
import MenuButton from '../../atoms/MenuButton';
import { ContentGroupProps } from './types';

const ContentGroup = (props: ContentGroupProps) => {
  const { button, heading, items } = props;
  const isItemsCollection = items && items.length > 0;
  const hasButton = button?.url && button?.title;

  const hasAnyContent = hasButton || isItemsCollection;

  if (!hasAnyContent) return null;

  return (
    <div className="rounded-4 bg-neutral-0 pb-16 lg:bg-transparent mb-8 lg:pb-0 lg:pt-0 lg:mb-30">
      <div className="mb-26">
        <Header {...heading} />
      </div>

      {isItemsCollection &&
        items.map((item, key) => (
          <Item {...item} key={`${heading.title}-${key}`} />
        ))}

      {hasButton && (
        <div className="mt-16 lg:mt-56 lg:mx-12">
          <MenuButton {...button} hasDefaultMarginTop />
        </div>
      )}
    </div>
  );
};

export default ContentGroup;
